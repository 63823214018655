<template>
    <div class="practiveone-container">
        <div class="practive-leftmenu">
            <ul>
                <li :class="{'active': activeTab==='eight'}" @click="changeMenu('eight')">幼儿照护保育综合实训数字资源系统</li>
                <li :class="{'active': activeTab==='nine'}" @click="changeMenu('nine')">早期教育教学综合实训数字资源系统</li>
            </ul>
        </div>
        <div class="practive-right">
            <PracticeItem8 v-show="activeTab==='eight'" />
            <PracticeItem9 v-show="activeTab==='nine'" />
        </div>
    </div>
</template>
<script>
import PracticeItem8 from './PracticeItem8'
import PracticeItem9 from './PracticeItem9'
export default {
    components: {PracticeItem8, PracticeItem9},
    props: {},
    data() {
        return {
            activeTab: 'eight'
        }
    },
    created() {},
    mounted() {
        this.$nextTick(() => {
            if (this.$route.query && this.$route.query.submenu) {
                this.activeTab = this.$route.query.submenu
            }
        })
    },
    methods: {
        changeMenu(menu) {
            this.activeTab = menu
        }
    }
}
</script>
<style lang='scss' scoped>
.practiveone-container {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    .practive-leftmenu {
        width: 300px;
        height: 490px;
        background: #F7F7F7;
        border-radius: 8px;
        padding-top: 16px;
        box-sizing: border-box;
        margin-right: 30px;
        &>ul {

            &>li {
                border-bottom: 1px solid #D9E6F5;
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                line-height: 20px;
                padding: 15px;
                box-sizing: border-box;
                cursor: pointer;
                &:hover {
                    color: #4A90E2;
                }
            }
            .active {
                color: #4A90E2;
                font-weight: 600;
            }
        }
    }
    .practive-right {
        width: 850px;
    }
}
</style>
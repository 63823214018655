<template>
    <div class="practiveitem2-container">
        <div class="practive-toptitle">
            <p>儿童发展心理学</p>
            <span>综合实训数字资源系统（PC版）</span>
        </div>
        <p class="practive-subtitle">服务于学前教育专业《学前心理学》核心课程，包含学前儿童心理发展的遗传与生理基础、学前儿童运动技能与感知觉发展、学前儿童注意与记忆发展、学前儿童思维与智力发展、学前儿童想象发展与游戏、学前儿童语言发展、学前儿童情绪发展、学前儿童人格发展、学前儿童社会关系与道德发展等案例资源，知识点考察题目及心理学经典实验模拟操作实训等内容。</p>
        <img class="pics7" :src="require('@/assets/images/index/item10.png')" alt="">
        <p class="pics-tips">用户界面</p>
        <div class="banquan">© 2021 文泉幼教. 版权所有. 儿童发展心理学综合实训数字资源系统.</div>
    </div>
</template>
<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            
        }
    },
    created() {},
    mounted() {},
    methods: {
        
    }
}
</script>
<style lang='scss' scoped>
.practiveitem2-container {
    .practive-toptitle {
        height: 72px;
        background: linear-gradient(90deg, #0A56B1 0%, #607FFF 100%);
        border-radius: 8px;
        width: 100%;
        color: #fff;
        padding: 9px 30px;
        box-sizing: border-box;
        &>p {
            font-size: 24px;
            font-weight: 500;
            line-height: 33px;
        }
        &>span {
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
        }
    }
    .practive-subtitle {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 26px;
        padding: 30px 26px;
        box-sizing: border-box;
    }
    .practive-pics {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        &>img {
            width: 420px;
        }
    }
    .pics-tips {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        margin: 15px 0 0;
        text-align: center;
    }
    .pics7 {
        width: 712px;
        text-align: center;
        display: block;
        margin: auto;
    }
    .banquan {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        height: 60px;
        background: #F7F7F7;
        border-radius: 8px;
        padding: 20px 30px;
        box-sizing: border-box;
        margin-top: 40px;
        margin-bottom: 80px;
    }
}
</style>
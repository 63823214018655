<template>
    <div class="practiveitem2-container">
        <div class="practive-toptitle">
            <p>学前教育环境创设</p>
            <span>综合实训数字资源系统（PC版）</span>
        </div>
        <p class="practive-subtitle">通过给定情境或者命题的环境创设实训任务内容，由学生在庞大的环境创设素材库中，选取对应素材进行创意设计。学生也可以扫描导入自己制作的手工素材存入自定义库，并导出设计平面图供教师进行作业考核。同时，该平台提供数十项真实环创案例与视频解析，学生可进行分析评价，并完善方案。</p>
        <img class="pics7" :src="require('@/assets/images/index/item7.png')" alt="">
        <p class="pics-tips">用户界面</p>
        <div class="banquan">© 2021 文泉幼教. 版权所有. 学前教育环境创设综合实训数字资源系统.</div>
    </div>
</template>
<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            
        }
    },
    created() {},
    mounted() {},
    methods: {
        
    }
}
</script>
<style lang='scss' scoped>
.practiveitem2-container {
    .practive-toptitle {
        height: 72px;
        background: linear-gradient(90deg, #0A56B1 0%, #607FFF 100%);
        border-radius: 8px;
        width: 100%;
        color: #fff;
        padding: 9px 30px;
        box-sizing: border-box;
        &>p {
            font-size: 24px;
            font-weight: 500;
            line-height: 33px;
        }
        &>span {
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
        }
    }
    .practive-subtitle {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 26px;
        padding: 30px 26px;
        box-sizing: border-box;
    }
    .practive-pics {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        &>img {
            width: 420px;
        }
    }
    .pics-tips {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        margin: 15px 0 0;
        text-align: center;
    }
    .pics7 {
        width: 738px;
        text-align: center;
        display: block;
        margin: auto;
    }
    .banquan {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        height: 60px;
        background: #F7F7F7;
        border-radius: 8px;
        padding: 20px 30px;
        box-sizing: border-box;
        margin-top: 40px;
        margin-bottom: 80px;
    }
}
</style>
<template>
    <div class="practicethree-container">
        <p class="ruanjian">实训室软件配置</p>
        <p class="xitong">学前教育专业综合实训数字资源系统、早期教育专业综合实训数字资源系统。</p>
        <el-carousel indicator-position="outside" arrow="always">
            <el-carousel-item>
                <div class="slide-items">
                    <img :src="require('@/assets/images/index/3-1.png')" alt="">
                    <img :src="require('@/assets/images/index/3-2.png')" alt="">
                    <img :src="require('@/assets/images/index/3-3.png')" alt="">
                </div>
            </el-carousel-item>
            <el-carousel-item>
                <div class="slide-items">
                    <img :src="require('@/assets/images/index/3-8.png')" alt="">
                    <img :src="require('@/assets/images/index/3-9.png')" alt="">
                    <img :src="require('@/assets/images/index/3-10.png')" alt="">
                </div>
            </el-carousel-item>
        </el-carousel>
        <div class="yingjian">
            <img :src="require('@/assets/images/index/3-4.png')" alt="">
            <div>
                <p class="common-title">实训室硬件配置</p>
                <span>VR全沉浸式头盔、VR教学体验一体机、桌面一体机、智慧纳米黑板、AR互动操作台、创客小组学习环境。</span>
            </div>
        </div>
        <p class="ruanjian" style="margin-top: 46px;">软硬件一体化的实训室功能</p>
        <p class="xitong">让教育过程更生动有趣，让教学内容直观可视化，课程体系化程度提高，解决场地条件的限<br />制问题，与专业教学知识点完美融合。</p>
        <el-carousel indicator-position="outside" arrow="always">
            <el-carousel-item>
                <div class="slide-items">
                    <img :src="require('@/assets/images/index/3-5.png')" alt="">
                    <img :src="require('@/assets/images/index/3-6.png')" alt="">
                    <img :src="require('@/assets/images/index/3-7.png')" alt="">
                </div>
            </el-carousel-item>
            <el-carousel-item>
                <div class="slide-items">
                    <img :src="require('@/assets/images/index/3-11.png')" alt="">
                    <img :src="require('@/assets/images/index/3-12.png')" alt="">
                    <img :src="require('@/assets/images/index/3-13.png')" alt="">
                </div>
            </el-carousel-item>
        </el-carousel>
        <div class="banquan">© 2021 文泉幼教. 版权所有. VR/AR多维智慧互动实训室..</div>
    </div>
</template>
<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            
        }
    },
    created() {},
    mounted() {},
    methods: {
        
    }
}
</script>
<style lang='scss' scoped>
.practicethree-container {
    padding: 46px 0 80px;
    box-sizing: border-box;
    .ruanjian {
        font-size: 24px;
        font-weight: 500;
        color: #333333;
        line-height: 33px;
        margin-bottom: 24px;
        text-align: center;
    }
    .xitong {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 26px;
        margin-bottom: 58px;
        text-align: center;
    }
    /deep/ .el-carousel__container {
        height: 200px;
    } 
    .slide-items {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        &>img {
            width: 350px;
            height: 200px;
            margin: 0 7px;
        }
    }
    .yingjian {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 30px 48px 30px 68px;
        box-sizing: border-box;
        height: 440px;
        background-color: #F6F9FD;
        margin-top: 10px;
        &>div {
            width: 430px;
            &>span {
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                margin-top: 24px;
                display: block;
            }
        }
        &>img {
            height: 380px;
        }
    }
    .banquan {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        height: 60px;
        background: #F7F7F7;
        border-radius: 8px;
        padding: 20px 30px;
        box-sizing: border-box;
        margin-top: 30px;
    }
}
</style>
<template>
    <div class="practice-container">
        <div class="common-first-part">
            <div>
                <p class="">综合实训</p>
                <span>以不同的教学内容为主线，配以可视化、直观化的虚拟仿真</span>
                <span style="margin: 0;">表现方式，对学前教育专业所包含的主要教学内容进行展现。</span>
            </div>
            <img :src="require('@/assets/images/index/shixun-1.png')" alt="">
        </div>
        <div class="slide-menus">   
            <div class="slide-menuitem" :class="{'active': currentActive === 'one'}" @click="changeMenu('one')">学前教育专业<br />综合实训数字资源系统 <br />（3-6岁）</div>
            <div class="slide-menuitem" :class="{'active': currentActive === 'two'}" @click="changeMenu('two')">早期教育专业<br />综合实训数字资源系统<br />（0-3岁）</div>
            <div class="slide-menuitem" :class="{'active': currentActive === 'three'}" @click="changeMenu('three')">VR/AR多维智慧<br />互动实训室</div>
            <div class="slide-menuitem" :class="{'active': currentActive === 'four'}" @click="changeMenu('four')">学前教育理<br />虚实一体化实训基地</div>
        </div>
        <PractiveOne v-if="currentActive === 'one'" />
        <PractiveTwo v-if="currentActive === 'two'" />
        <PractiveThree v-if="currentActive === 'three'" id="menuthree" />
        <PractiveFour v-if="currentActive === 'four'" id="menufour" />
    </div>
</template>
<script>
import PractiveOne from './practice/PractiveOne'
import PractiveTwo from './practice/PractiveTwo'
import PractiveThree from './practice/PractiveThree'
import PractiveFour from './practice/PractiveFour'
export default {
    components: {PractiveOne, PractiveTwo, PractiveThree, PractiveFour},
    props: {},
    data() {
        return {
            currentActive: 'one'
        }
    },
    created() {
        
    },
    mounted() {
        this.$nextTick(() => {
            if (this.$route.query && this.$route.query.tab) {
                let tab = this.$route.query.tab
                if (tab==='4') {
                    this.currentActive = 'four'
                } else if (tab==='3') {
                    this.currentActive = 'three'
                } else if (tab==='2') {
                    this.currentActive = 'two'
                } else if (tab==='1') {
                    this.currentActive = 'one'
                }
            }
        })
    },
    methods: {
        changeMenu(menu) {
            this.currentActive = menu
        }
    }
}
</script>
<style lang='scss' scoped>
.practice-container {
    .slide-menus {
        border-bottom: 1px solid #E7E7E7;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
        .slide-menuitem {
            font-size: 16px;
            font-weight: 400;
            color: #000000;
            line-height: 24px;
            text-align: center;
            padding: 22px 0 15px;
            cursor: pointer;
            height: 109px;
            box-sizing: border-box;
            &:hover {
                color: #4A90E2;
            }
        }
        .active {
            font-weight: 500;
            color: #4A90E2;
            position: relative;
            &:after {
                content: '';
                display: block;
                width: 120px;
                height: 3px;
                background: #4A90E2;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
}
</style>
<template>
    <div class="practiveone-container">
        <div class="practive-leftmenu">
            <ul>
                <li :class="{'active': activeTab==='one'}" @click="changeMenu('one')">学前教育一日活动综合实训数字资源系统</li>
                <li :class="{'active': activeTab==='two'}" @click="changeMenu('two')">学前卫生保育与膳食综合实训数字资源系统</li>
                <li :class="{'active': activeTab==='three'}" @click="changeMenu('three')">学前教育环境创设综合实训数字资源系统</li>
                <li :class="{'active': activeTab==='four'}" @click="changeMenu('four')">学前安全应急综合实训数字资源系统</li>
                <li :class="{'active': activeTab==='five'}" @click="changeMenu('five')">儿童发展心理学综合实训数字资源系统</li>
                <li :class="{'active': activeTab==='six'}" @click="changeMenu('six')">幼儿教育VR综合实训数字资源系统</li>
                <li :class="{'active': activeTab==='seven'}" @click="changeMenu('seven')">学前教育互动综合实训数字资源系统</li>
            </ul>
        </div>
        <div class="practive-right">
            <PracticeItem1 v-show="activeTab==='one'" />
            <PracticeItem2 v-show="activeTab==='two'" />
            <PracticeItem3 v-show="activeTab==='three'" />
            <PracticeItem4 v-show="activeTab==='four'" />
            <PracticeItem5 v-show="activeTab==='five'" />
            <PracticeItem6 v-show="activeTab==='six'" />
            <PracticeItem7 v-show="activeTab==='seven'" />
        </div>
    </div>
</template>
<script>
import PracticeItem1 from './PracticeItem1'
import PracticeItem2 from './PracticeItem2'
import PracticeItem3 from './PracticeItem3'
import PracticeItem4 from './PracticeItem4'
import PracticeItem5 from './PracticeItem5'
import PracticeItem6 from './PracticeItem6'
import PracticeItem7 from './PracticeItem7'
export default {
    components: {PracticeItem1, PracticeItem2, PracticeItem3, PracticeItem4, PracticeItem5, PracticeItem6, PracticeItem7},
    props: {},
    data() {
        return {
            activeTab: 'one'
        }
    },
    created() {},
    mounted() {
        this.$nextTick(() => {
            if (this.$route.query && this.$route.query.submenu) {
                this.activeTab = this.$route.query.submenu
            }
        })
    },
    methods: {
        changeMenu(menu) {
            this.activeTab = menu
        }
    }
}
</script>
<style lang='scss' scoped>
.practiveone-container {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    .practive-leftmenu {
        width: 300px;
        height: 490px;
        background: #F7F7F7;
        border-radius: 8px;
        padding-top: 16px;
        box-sizing: border-box;
        margin-right: 30px;
        &>ul {

            &>li {
                border-bottom: 1px solid #D9E6F5;
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                line-height: 20px;
                padding: 15px;
                box-sizing: border-box;
                cursor: pointer;
                &:hover {
                    color: #4A90E2;
                }
            }
            .active {
                color: #4A90E2;
                font-weight: 600;
            }
        }
    }
    .practive-right {
        width: 850px;
    }
}
</style>
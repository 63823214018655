<template>
    <div class="practiveitem1-container">
        <div class="practive-toptitle">
            <p>学前教育一日活动</p>
            <span>综合实训数字资源系统（PC版）</span>
        </div>
        <p class="practive-subtitle">服务于学前教育专业《学前教育学》《幼儿保健学》《幼儿园课程》《幼儿心理学》等核心课程，模拟建模完整真实的幼儿园场景，以不同的教学内容为主线，配以可视化、直观化的虚拟仿真表现方式，对幼儿教育所包含的主要教学内容进行展现。</p>
        <div class="practive-pics">
            <img :src="require('@/assets/images/index/item1.png')" alt="">
            <img :src="require('@/assets/images/index/item2.png')" alt="">
        </div>
        <p class="pics-tips">一日活动仿真实训</p>
        <p class="practive-subtitle">系统实训任务以角色扮演的方式进行，实训方式为闯关制趣味游戏，按照学习流程和顺序设置多道关卡，与系统角色进行虚拟互动，按照课程知识点进行考核，系统具备计时、计分、倒计时、结果记录、成绩统计、成绩排行等功能。</p>
        <img class="pics3" :src="require('@/assets/images/index/item3.png')" alt="">
        <p class="pics-tips">数据管理功能展示界面</p>
        <div class="banquan">© 2021 文泉幼教. 版权所有. 学前教育理虚实一体化实训基地.</div>
    </div>
</template>
<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            
        }
    },
    created() {},
    mounted() {},
    methods: {
        
    }
}
</script>
<style lang='scss' scoped>
.practiveitem1-container {
    .practive-toptitle {
        height: 72px;
        background: linear-gradient(90deg, #0A56B1 0%, #607FFF 100%);
        border-radius: 8px;
        width: 100%;
        color: #fff;
        padding: 9px 30px;
        box-sizing: border-box;
        &>p {
            font-size: 24px;
            font-weight: 500;
            line-height: 33px;
        }
        &>span {
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
        }
    }
    .practive-subtitle {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 26px;
        padding: 30px 26px;
        box-sizing: border-box;
    }
    .practive-pics {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        &>img {
            width: 420px;
        }
    }
    .pics-tips {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        margin: 15px 0 0;
        text-align: center;
    }
    .pics3 {
        width: 500px;
        text-align: center;
        display: block;
        margin: auto;
    }
    .banquan {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        height: 60px;
        background: #F7F7F7;
        border-radius: 8px;
        padding: 20px 30px;
        box-sizing: border-box;
        margin-top: 40px;
        margin-bottom: 80px;
    }
}
</style>
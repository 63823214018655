<template>
    <div class="practicefour-container">
        <p class="ruanjian">实训基地软件配置</p>
        <p class="xitong">学前教育专业综合实训数字资源系统、早期教育专业综合实训数字资源系统。</p>
        <el-carousel indicator-position="outside" arrow="always">
            <el-carousel-item>
                <div class="slide-items">
                    <img :src="require('@/assets/images/index/peizhi-1.png')" alt="">
                    <img :src="require('@/assets/images/index/peizhi-2.png')" alt="">
                    <img :src="require('@/assets/images/index/peizhi-3.png')" alt="">
                </div>
            </el-carousel-item>
            <el-carousel-item>
                <div class="slide-items">
                    <img :src="require('@/assets/images/index/4-1.png')" alt="">
                    <img :src="require('@/assets/images/index/4-2.png')" alt="">
                    <img :src="require('@/assets/images/index/4-3.png')" alt="">
                </div>
            </el-carousel-item>
        </el-carousel>
        <div class="yingjian">
            <div>
                <p class="common-title">实训基地</p>
                <span>VR/AR虚拟仿真实训室、幼儿园模拟实训室、学前卫生保育实训室、学前安全教育实训室、1+X幼儿照护实训室、幼儿园营养膳食实训室、早期教育虚拟仿真实训室、五大领域教学活动实训室、儿童心理与行为观察室等实体环境的搭建，设备、教具的采购。</span>
            </div>
            <img :src="require('@/assets/images/index/zonghe-1.png')" alt="">
        </div>
        <div class="gongneng">
            <p class="common-title">理虚实一体化实训基地功能</p>
            <div class="gongneng-lists">
                <div class="gongneng-items">
                    <div class="left"><img :src="require('@/assets/images/index/gongneng-1.png')" alt=""></div>
                    <div class="right">
                        <p>教学实训</p>
                        <span>情境式渗透教育，过程生动有趣</span>
                    </div>
                </div>
                <div class="gongneng-items">
                    <div class="left"><img :src="require('@/assets/images/index/gongneng-2.png')" alt=""></div>
                    <div class="right">
                        <p>课程培训</p>
                        <span>课程体系化程度提高</span>
                    </div>
                </div>
                <div class="gongneng-items">
                    <div class="left"><img :src="require('@/assets/images/index/gongneng-3.png')" alt=""></div>
                    <div class="right">
                        <p>模拟教学</p>
                        <span>教学内容直观可视化</span>
                    </div>
                </div>
                <div class="gongneng-items">
                    <div class="left"><img :src="require('@/assets/images/index/gongneng-4.png')" alt=""></div>
                    <div class="right">
                        <p>解决问题</p>
                        <span>解决现场不可预见性问题</span>
                    </div>
                </div>
                <div class="gongneng-items">
                    <div class="left"><img :src="require('@/assets/images/index/gongneng-5.png')" alt=""></div>
                    <div class="right">
                        <p>了解领域</p>
                        <span>将专业教学知识点融入幼儿园应用领域</span>
                    </div>
                </div>
                <div class="gongneng-items">
                    <div class="left"><img :src="require('@/assets/images/index/gongneng-6.png')" alt=""></div>
                    <div class="right">
                        <p>优质设备</p>
                        <span>打造软硬件一体化的学习环境，提升学习体验</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="banquan">© 2021 文泉幼教. 版权所有. 学前教育理虚实一体化实训基地.</div>
    </div>
</template>
<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            
        }
    },
    created() {},
    mounted() {},
    methods: {
        
    }
}
</script>
<style lang='scss' scoped>
.practicefour-container {
    padding: 46px 0 80px;
    box-sizing: border-box;
    .ruanjian {
        font-size: 24px;
        font-weight: 500;
        color: #333333;
        line-height: 33px;
        margin-bottom: 24px;
        text-align: center;
    }
    .xitong {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 26px;
        margin-bottom: 58px;
        text-align: center;
    }
    /deep/ .el-carousel__container {
        height: 200px;
    } 
    .slide-items {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        &>img {
            width: 350px;
            height: 200px;
            margin: 0 7px;
        }
    }
    .yingjian {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 30px 48px 30px 68px;
        box-sizing: border-box;
        height: 440px;
        background-color: #F6F9FD;
        margin-top: 10px;
        &>div {
            width: 430px;
            &>span {
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                margin-top: 24px;
                display: block;
            }
        }
        &>img {
            height: 380px;
        }
    }
    .gongneng {
        padding-top: 56px;
        &>p {
            text-align: center;
            padding-bottom: 32px;
        }
        .gongneng-lists {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            .gongneng-items {
                width: 320px;
                height: 98px;
                background: #F1FCFD;
                border-radius: 8px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                padding: 0 16px;
                box-sizing: border-box;
                margin-top: 28px;
                &:nth-child(2n) {
                    background: #F1F7FD;
                }
                &:nth-child(3n-1) {
                    margin-left: 86px;
                    margin-right: 86px;
                }
                .left {
                    width: 72px;
                    height: 72px;
                    background: #FFFFFF;
                    border-radius: 50%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    margin-right: 20px;
                    &>img {
                        width: 48px;
                        height: 48px;
                    };
                }
                .right {
                    &>p {
                        font-size: 18px;
                        font-weight: 600;
                        color: #333333;
                        line-height: 26px;
                    }
                    &>span {
                        font-size: 14px;
                        font-weight: 400;
                        color: #666666;
                        line-height: 20px;
                    }
                }   
            }
        }
    }
    .banquan {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        height: 60px;
        background: #F7F7F7;
        border-radius: 8px;
        padding: 20px 30px;
        box-sizing: border-box;
        margin-top: 100px;
    }
}
</style>